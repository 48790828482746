<template>
	<div class="commendBox">
		<div class="zbtj flex align_items_c justify_content_sb">
			<div class="zbjtTitle">
				<img
					src="https://zy.metaera.media/assets/images/home/tuijian.png"
				/>主编推荐</div
			>
			<div class="rightMoreBtn mainbackgroundcolor">查看更多</div>
		</div>
		<div class="tjList flex align_items_c justify_content_sb">
			<div class="tjItem" v-for="(item, index) in zbtjList" :key="index">
				<div class="flex tjItemLabel pointer">
					<div
						v-for="(items, indexs) in item.label_info"
						:key="indexs"
						@click="toLabel(items)"
					>
						{{ items.name }}</div
					>
				</div>
				<div
					class="tjItemDet pointer"
					@click="
						$router.push({
							path: '/gw_detail',
							query: { id: item.id },
						})
					"
				>
					<span class="tipPoint"></span>
					<span class="content">{{
						localLanguage == 2
							? item.title
								? item.title
								: item.title
							: item.title
					}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { eventBus } from "../../components/pc/leftTab";
export default {
	props: ["zbtjList"],
	data() {
		return {
			localLanguage: 1,
		};
	},
	mounted() {
		eventBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {
		// 跳转标签详情
		toLabel(items) {
			this.$router.push({
				path: "/gw_dex",
				query: { id: items.id, name: items.name },
			});
		},
	},
};
</script>

<style scoped>
.zbtj {
	margin-top: 30px;
}
.zbtj img,
.rightMoreBtn,
.tipPoint {
	display: none;
}
.zbtj div:nth-child(1) {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.tjList {
	margin-top: 17px;
}

.tjItem {
	width: 157px;
	height: 100px;
}

.tjItemLabel div {
	height: 25px;
	padding: 0 9px;
	font-size: 12px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #000000;
	background: #f5f5f6;
	border-radius: 13px 13px 13px 13px;
	opacity: 1;
	line-height: 25px;
	margin-left: 11px;
}

.tjItemLabel div:nth-child(1) {
	margin-left: 0;
}

.tjItemDet {
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
	line-height: 24px;
	margin-top: 12px;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.content {
	overflow: hidden;
	text-overflow: ellipsis;
	color: #000;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	line-height: 24px;
	text-overflow: -o-ellipsis-lastline;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
@media screen and (max-width: 950px) {
	.zbtj img,
	.rightMoreBtn,
	.tipPoint,
	.content {
		display: block;
	}
	.commendBox {
		background: #fafafa;
		padding: 15px 0;
	}
	.zbtj {
		padding: 0 12px;
		margin-top: 0px;
	}
	.zbjtTitle {
		display: flex;
		align-items: center;
	}
	.zbjtTitle img {
		margin-right: 4px;
		width: 18px;
	}
	.tjList {
		margin-top: 6px;
		padding-left: 16px;
		padding-right: 16px;
		display: list-item;
	}
	.tjItem {
		width: 100%;
		height: 21px;
	}
	.tjItemLabel {
		display: none;
	}

	.tjItemDet {
		display: flex;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		align-items: center;
		margin-top: 8px;
	}
	.rightMoreBtn {
		width: 65px;
		height: 16px;
		line-height: 16px;
		text-align: center;
		background: linear-gradient(140deg, #bf4cfc 0%, #3880fa 100%);
		border-radius: 8px;
		font-size: 11px;
		color: #fff;
	}
	.tipPoint {
		width: 4px;
		height: 4px;
		border-radius: 50%;
		background: #000;
		flex-shrink: 0;
		margin-right: 8px;
	}

	.content {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		line-height: 21px;
		font-size: 15px;
		font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
			"Microsoft YaHei", Arial, sans-serif;
		font-weight: 400;
		color: #000;
	}
}
</style>
