<template>
	<div class="dl_main">
		<div class="dl_title">
			<p>Download</p>
			<p>MetaEra App</p>
		</div>
		<div class="dl_ios">
			<img
				src="https://zy.metaera.media/assets/images/aboutus/download_ios.png"
				alt=""
			/>
			<div>iOS</div>
		</div>
		<div class="dl_android">
			<img
				src="https://zy.metaera.media/assets/images/aboutus/download_android.png"
				alt=""
			/>
			<div>Android</div>
		</div>
		<div class="dl_img">
			<img
				src="https://zy.metaera.media/assets/images/aboutus/download.png"
				alt=""
			/>
		</div>
	</div>
</template>

<script>
export default {
	props: ["item"],
	data() {
		return {
			allData: [],
		};
	},

	methods: {},
};
</script>

<style scoped>
.dl_main {
	display: flex;
	margin-top: 30px;
	top: 30px;
	width: 290px;
	height: 100px;
	border-radius: 10px;
	opacity: 1;
	/* 纯白 */
	background: #ffffff;
}
.dl_title {
	position: absolute;
	margin-left: 10px;
	margin-top: 16px;
}
.dl_title p {
	font-family: PingFang SC !important;
	font-size: 14px !important;
	font-weight: 600 !important;
	line-height: 18px;
	letter-spacing: 0em;
	/* 纯黑 */
	color: #000000;
}

.dl_ios,
.dl_android {
	display: flex;
	justify-content: center;
	margin-top: 59px;
	margin-left: 10px;
	width: 54px;
	height: 24px;
	border-radius: 5px;
	opacity: 1;
	/* 主色（一级颜色） */
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);

	align-items: center;
}

.dl_android {
	width: 86px;
	background: #333333;
}
.dl_ios img,
.dl_android img {
	width: 12px;
	height: 12px;
}
.dl_ios div,
.dl_android div {
	margin-left: 4px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	font-weight: normal;
	line-height: 14px;
	letter-spacing: 0em;
	/* 纯白 */
	color: #ffffff;
}

.dl_img {
	margin-left: 50px;
	margin-top: 15px;
	width: 68px;
	height: 68px;
	opacity: 1;
}
.dl_img img {
	width: 100%;
	height: 100%;
}
</style>
