var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"commendBoxPC"},[_vm._m(0),_c('div',{staticClass:"commendBoxPC_content"},[_c('div',{staticClass:"commendBoxPC_top",on:{"click":function($event){return _vm.$router.push({
					path: '/gw_detail',
					query: { id: _vm.zbtjList[0].id },
				})}}},[_c('div',{staticClass:"commendBoxPC_top_img"},[_c('img',{attrs:{"src":_vm.zbtjList[0].pic,"alt":""}})]),_c('div',{staticClass:"commendBoxPC_top_content"},[_c('div',{staticClass:"commendBoxPC_top_content1"},[_c('div',[_c('span',{staticClass:"commendBoxPC_top_title pointer hovercolor"},[_vm._v(" "+_vm._s(this.localLanguage == 2 ? _vm.zbtjList[0].title ? _vm.zbtjList[0].title : _vm.zbtjList[0].title : _vm.zbtjList[0].title)+" ")])]),_c('div',[_c('span',{staticClass:"commendBoxPC_top_desc"},[_vm._v(" "+_vm._s(this.localLanguage == 2 ? _vm.zbtjList[0].illustrate_en ? _vm.zbtjList[0].illustrate_en : _vm.zbtjList[0].illustrate : _vm.zbtjList[0].illustrate)+" ")])])]),_c('div',{staticClass:"commendBoxPC_top_content2"},[_c('div',{staticClass:"commendBoxPC_top_Left_line"}),_c('div',{staticClass:"commendBoxPC_top_Left_author"},[_vm._v(_vm._s(_vm.zbtjList[0].author.nickname)+" ")]),_c('div',[_vm._v("·")]),_c('div',{staticClass:"commendBoxPC_top_Left_time"},[_vm._v(" "+_vm._s(_vm.zbtjList[0].release_time)+" ")])])])]),_c('ul',{staticClass:"commendBoxPC_bottom"},_vm._l((_vm.zbtjList),function(item,zbtjListIndex){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(zbtjListIndex > 0),expression:"zbtjListIndex > 0"}],key:zbtjListIndex,staticClass:"commendBoxPC_bottom_item",on:{"click":function($event){return _vm.$router.push({
						path: '/gw_detail',
						query: { id: item.id },
					})}}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"commendBoxPC_bottom_item_point"}),_c('div',{staticClass:"commendBoxPC_bottom_item_title pointer hovercolor"},[_vm._v(" "+_vm._s(_vm.localLanguage == 2 ? item.title ? item.title : item.title : item.title)+" ")])]),_c('div',{staticClass:"commendBoxPC_bottom_item_time"},[_vm._v(" "+_vm._s(item.release_time)+" ")])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zbtj flex align_items_c justify_content_sb"},[_c('div',{staticClass:"zbjtTitle"},[_vm._v(" 主编推荐")])])
}]

export { render, staticRenderFns }